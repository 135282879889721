<template>
  <!-- <div class="purchase-container"> -->
  <Card class="h-full purchase-container">
    <h5 class="text-lg font-semibold">Customers List</h5>
    <div class="flex items-center justify-end">
      <SearchInput placeholder="Email" class="mr-2" v-model="params.email" />
      <SearchInput placeholder="Customer Id" class="mr-2" v-model="params.customerId" />
      <Button text="Search filer" @click="handleSearch" class="mr-2" p="p-2" />
      <Button outline text="Clear filter" @click="clearSearch" p="p-2" />
    </div>
    <Hr />
    <Spinner class="text-center" v-if="loading" />
    <div class="table-container" v-else>
      <div>
        <div class="payment-table">
          <div class="payment-table__col font-semibold">S/N</div>
          <div class="payment-table__col font-semibold">Id</div>
          <div class="payment-table__col font-semibold">Name</div>
          <div class="payment-table__col font-semibold">Phone Number</div>
          <div class="payment-table__col font-semibold">Email</div>
          <div class="payment-table__col font-semibold">BVN</div>
          <div class="payment-table__col font-semibold">Credit status</div>
          <div class="payment-table__col font-semibold">Action</div>
        </div>
        <Hr />
      </div>
      <EmptyState description="No records found" v-if="!allCustomers.length" />

      <div v-for="(customer, index) in allCustomers" :key="customer.id">
        <div class="payment-table">
          <div class="payment-table__col">
            {{ index + 1 }}
          </div>
          <div class="payment-table__col">
            {{ customer.id }}
          </div>
          <div class="payment-table__col">{{ customer.firstname }} {{ customer.lastname }}</div>
          <div class="payment-table__col">
            {{ customer.phoneNumber || "N/A" }}
          </div>
          <div class="payment-table__col">
            {{ customer.email || "N/A" }}
          </div>
          <div class="payment-table__col">
            {{ customer.bvn || "N/A" }}
          </div>
          <div class="payment-table__col" v-if="customer.qualificationStatus">
            {{ customer.qualificationStatus.toLowerCase().replace(/_/g, " ") }}
          </div>
          <div class="payment-table__col" v-else>N/A</div>
          <div>
            <SecondaryButton text="View" @click="$router.push(`/customers/${customer.id}`)" />
          </div>
        </div>
        <Hr />
      </div>
      <Pagination :handleMore="handleMore" :loading="paginateLoading" @paginate="handlePagination" />
    </div>
  </Card>
  <!-- </div> -->
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import Hr from "@/UI/Hr";
  import { SecondaryButton } from "@/UI/Button";
  import { CUSTOMER_FINANCIAL_HEALTH } from "@/utils/api/customers";
  import { Modal } from "@/UI/Modals";
  import Pagination from "@/UI/Pagination";
  import { SearchInput } from "@/UI/Input";
  import { Button } from "@/UI/Button";
  import EmptyState from "@/UI/EmptyState";
  export default {
    components: {
      Hr,
      SecondaryButton,
      Modal,
      Pagination,
      SearchInput,
      Button,
      EmptyState,
      Spinner: () => import("@/UI/Spinner"),
    },
    data: () => ({
      paginateLoading: false,
      handleMore: false,
      params: {
        page: 0,
        pageSize: 20,
        email: "",
        customerId: "",
      },
      loading: false,
    }),
    computed: {
      ...mapState({
        allCustomers: (state) => state.customers.allCustomers,
        // loading: (state) => state.loading.loading,
      }),
    },
    mounted() {
      this.$route.query.email && (this.params.email = this.$route.query.email);
      this.$route.query.id && (this.params.customerId = this.$route.query.id);
      this.getCustomers();
    },
    methods: {
      ...mapActions("customers", ["getAllCustomers"]),
      ...mapActions("loading", ["setLoading"]),
      getCustomers(type) {
        type === "paginate" ? (this.paginateLoading = true) : (this.loading = true);
        // this.setLoading(true);
        this.getAllCustomers({ params: this.params, type: type })
          .then((res) => {
            if (res.data.data.length === this.params.pageSize) {
              // console.log(type);
              this.handleMore = true;
            } else {
              this.handleMore = false;
            }
            type === "paginate" ? (this.paginateLoading = false) : (this.loading = false);
          })
          .catch(() => {
            type === "paginate" ? (this.paginateLoading = false) : (this.loading = false);
          });
      },
      handlePagination() {
        this.paginateLoading = true;
        this.params.page++;
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            data: JSON.stringify(this.params),
          },
        });
        this.getCustomers("paginate");
      },
      viewCustomer(id) {
        this.setLoading(true);
        CUSTOMER_FINANCIAL_HEALTH(id).then((res) => {
          console.log(res);
          this.setLoading(false);
        });
      },
      clearSearch() {
        this.params.page = 0;
        this.params.customerId = "";
        this.params.email = "";
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {},
        });
        this.getCustomers();
      },
      handleSearch() {
        this.params.page = 0;
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            email: this.params.email,
            id: this.params.customerId,
          },
        });
        this.getCustomers();
      },
    },
  };
</script>
<style scoped>
  .purchase-container {
    min-height: calc(100vh - 10rem);
  }
  .payment-table {
    display: grid;
    grid-template-columns: 2.5rem 15rem 15rem 8rem 15rem 1fr 1fr 1fr;
    grid-column-gap: 10px;
    align-items: center;
  }
  .payment-table__col {
    padding: 0.8rem 0.5rem;
    /* white-space: nowrap; */
    font-size: 0.9rem;
  }
  .table-container {
    overflow: auto;
  }
</style>
